import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {NgProgressModule} from 'ngx-progressbar';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {DashboardPanelComponent} from 'public-shared/components/dashboard-panel/dashboard-panel.component';
import {DomainValidatorDirective} from 'public-shared/directives/validators/domain-validator.directive';
import {PublicDashboardComponent} from 'public-shared/pages/dashboard/public-dashboard.component';
import {NotFoundComponent} from 'public-shared/pages/not-found/not-found.component';
import {PublicAuthService} from 'public-shared/services/auth/public-auth.service';
import {TokenService} from 'public-shared/services/auth/token-validation.service';
import {PublicCacheService} from 'public-shared/services/cache/public-cache.service';
import {ForgotPasswordService} from 'public-shared/services/forgot-password/forgot-password.service';
import {InfoService} from 'public-shared/services/info/info.service';
import {LocalStorageService} from 'public-shared/services/local-storage/local-storage.service';
import {LoggerService} from 'public-shared/services/logging/logger.service';
import {PublicSecurityContextHolder} from 'public-shared/services/security/public-security-context-holder';
import {PipesModule} from './pipes/pipes.module';
import {ConfigService} from './services/init-config/config.service';
import {ToastrNotificationService} from './services/toastr/toastr.service';

@NgModule({
  declarations: [NotFoundComponent, PublicDashboardComponent, DashboardPanelComponent],
  imports: [
    DomainValidatorDirective,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    NgProgressModule,
    NgProgressHttpModule,
    PipesModule
  ],
  providers: [
    PublicSecurityContextHolder,
    PublicAuthService,
    ForgotPasswordService,
    PublicCacheService,
    LoggerService,
    TokenService,
    ToastrNotificationService,
    LocalStorageService,
    ConfigService,
    InfoService
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    NgProgressModule,
    NgProgressHttpModule,
    PipesModule,
    NotFoundComponent,
    PublicDashboardComponent,
    DashboardPanelComponent
  ]
})
export class SharedPublicModule {}
