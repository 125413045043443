import {QUERY_PARAMS} from '../../constants/shared.constants';
import {CookiesClaimType} from '../jwt-token/jwt-token.helper';
import {cookiesAllowed, isCookiePreferenceSet, setCookiePreference} from '../local-storage/local-storage.helper';

// Add the cookies query param to "after login" url
export const appendCookiesQueryParamToUrl = (url: string): string => {
  const cookieConsent = isCookiePreferenceSet() ? cookiesAllowed() : '';
  const divider = url.includes('?') ? '&' : '?';

  // Append cookies consent only if there's a value for it
  const redirectUrl = cookieConsent ? `${url}${divider}${QUERY_PARAMS.COOKIES}=${cookieConsent}` : url;

  return redirectUrl;
};

// Save cookie preference to localstorage
export const saveTokenCookiesClaimToLocalStorage = (cookies: CookiesClaimType | undefined): void => {
  // Cookies preferences must stay not set, if the value is undefined/ not set on login
  if (cookies) {
    setCookiePreference(cookies);
  }
};
