import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgForm, NgModel} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {UntilDestroy} from '@jumio/portals.core';
import {Subscription} from 'rxjs';
import {ImageService} from 'shared/services/image/image.service';

@UntilDestroy()
@Component({
  template: ''
})
export class AbstractImageUploaderComponent implements AfterViewInit, ControlValueAccessor {
  @Input() public accept: string | undefined;
  @Input() public resetToDefaultButtonLabel = 'Reset to default';
  @Input() public imageWidthRestriction: number | undefined;
  @Input() public imageHeightRestriction: number | undefined;
  @Input() public name: string | undefined;
  @Input() public buttonsDisabled: boolean = false;
  @Input() public showRemoveButton = false;
  @Input() public maxFileSize: number | undefined;

  @Output() public onComponentReady = new EventEmitter<boolean>();

  @ViewChild('thumbnail') public thumbnail: ElementRef | undefined;
  @ViewChild('fileinput') public fileinput: ElementRef | undefined;
  @ViewChild('model') public model: NgModel | undefined;

  public uploadedImageThumbnail: string | null | undefined;
  public defaultThumbnail: string | undefined;

  public files: File[] = [];
  public imageHeight: number | undefined;
  public imageWidth: number | undefined;
  public currentThumbnail: string | null | undefined;
  public hideResetButton = false;
  public currentBlob: Blob | null | undefined;

  /**
   * True, if the curren thumbnail is already a SafeUrl.
   * This is true when the user selects an image, but
   * false, when the current thumbanils are loaded from the server.
   */
  public isSecureThumbnail = false;

  public readonly subscription = new Subscription();

  constructor(
    protected sanitizer: DomSanitizer,
    protected ngForm: NgForm,
    protected imageService: ImageService
  ) {}

  public propagateChange: any = () => {};
  public propagateTouch: any = () => {};

  public getTypeClass(fileType: string): string {
    return fileType.substring(0, fileType.indexOf('/'));
  }

  public isWildcard(fileType: string): boolean {
    return fileType.indexOf('*') !== -1;
  }

  public getFileExtension(file: File): string {
    return '.' + file.name.split('.').pop();
  }

  public isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  public isFileSizeAllowed(file: File): boolean {
    //@ts-ignore
    return file.size <= this.maxFileSize;
  }

  public get maxFileSizeText(): string {
    //@ts-ignore
    return `${this.maxFileSize / 1024 / 1024} MB`;
  }

  public blobToFile(blob: Blob, fileName: string): File {
    const file: any = blob;
    file.lastModifiedDate = new Date();
    file.name = fileName;
    return blob as File;
  }

  public setDefaultImage(img: string): void {
    this.defaultThumbnail = img;
  }

  public loadImage(img: string | null): void {
    this.uploadedImageThumbnail = img;
    this.resetComponent();
  }

  public resetComponent(): void {
    this.isSecureThumbnail = false;
    this.currentBlob = null;
    this.currentThumbnail = null;
    this.files = [];
    if (this.fileinput) {
      this.fileinput.nativeElement.value = '';
    }

    if (!this.uploadedImageThumbnail) {
      // set the default image if custom is not defined for this component
      this.setThumbnail(this.defaultThumbnail);
    } else {
      // otherwise download the custom image separately, and add it to the files array for reupload
      this.subscription.add(
        this.imageService.getImageFile$(this.uploadedImageThumbnail).subscribe((blob: Blob) => {
          this.currentBlob = blob;

          if (this.uploadedImageThumbnail !== this.defaultThumbnail) {
            //@ts-ignore
            const uploadedFile = this.blobToFile(this.currentBlob, this.name);
            this.files.push(uploadedFile);
          }
        })
      );
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.model) {
        //@ts-ignore
        this.ngForm.form.addControl(this.name, this.model.control);
        this.onComponentReady.emit(true);
      }
    });
  }

  public setThumbnail(src: any): void {
    this.currentThumbnail = src;
  }

  public resetThumbnail(): void {
    this.imageHeight = this.thumbnail?.nativeElement.naturalHeight;
    this.imageWidth = this.thumbnail?.nativeElement.naturalWidth;
  }

  public onFileSelect(event: {dataTransfer: {files: any}; target: {files: any}}, processAsStyle = false): void {
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    if (files.length === 0) {
      return;
    }
    const file = files[0];

    this.propagateTouch();
    if (this.isValidFile(file) && this.isImage(file)) {
      const reader = new FileReader();
      reader.onload = (): void => {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);

        this.showRemoveButton = true;
        this.files.push(file);

        if (!this.isFileSizeAllowed(file)) {
          this.setFormError({invalidSize: true});
        } else {
          this.clearErrors();
          this.propagateChange();
        }

        this.setThumbnailFromFiles(files, reader.result as string, processAsStyle);
      };

      reader.readAsDataURL(file);
    } else {
      this.setFormError({invalidFile: true});
    }
  }

  public isValidFile(file: File): boolean {
    return !(this.accept && !this.isFileTypeValid(file));
  }

  public removeSelectedImage(): void {
    this.resetComponent();
    this.showRemoveButton = false;
    this.resetImage();
  }

  public resetToDefaultImage(): void {
    this.loadImage(null);
    this.resetImage();
  }

  public resetImage(): void {
    this.propagateTouch();
    this.resetHTMLInputElement();
    this.propagateChange();
  }

  public resetHTMLInputElement(): void {
    //@ts-ignore
    this.fileinput.nativeElement.value = '';
  }

  public clearErrors(): void {
    //@ts-ignore
    this.ngForm.getControl(this.model).setErrors(null);
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public writeValue(_: any): void {}

  public setFormError(error: Record<string, unknown>): void {
    //@ts-ignore
    this.ngForm.getControl(this.model).setErrors(error);
  }

  public setThumbnailFromFiles(files: any[], objectURL: string, processAsStyle: boolean): void {
    if (files[0] && this.isImage(files[0])) {
      this.isSecureThumbnail = true;
      const url = processAsStyle
        ? this.sanitizer.bypassSecurityTrustStyle(`url(${objectURL})`)
        : this.sanitizer.bypassSecurityTrustUrl(objectURL);
      this.setThumbnail(url);
    } else {
      this.setThumbnail(this.defaultThumbnail);
    }
  }

  private isFileTypeValid(file: File): boolean {
    const acceptableTypes = this.accept?.split(',');
    //@ts-ignore
    for (const type of acceptableTypes) {
      const acceptable = this.isWildcard(type)
        ? this.getTypeClass(file.type) === this.getTypeClass(type)
        : file.type === type || this.getFileExtension(file) === type;
      if (acceptable) {
        return true;
      }
    }

    return false;
  }

  public get showResetToDefaultButton(): boolean | string | null | undefined {
    return this.uploadedImageThumbnail && this.defaultThumbnail !== this.uploadedImageThumbnail;
  }

  public get isCustomImage(): boolean {
    return !this.isSecureThumbnail && !Boolean(this.currentThumbnail) && Boolean(this.currentBlob);
  }

  public get isDefaultImage(): boolean {
    return !this.isSecureThumbnail && Boolean(this.currentThumbnail) && !Boolean(this.currentBlob);
  }
}
