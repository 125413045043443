import {ICommonMetadata} from '../../interfaces/config.interface';

export const createCommonMetadata = (): ICommonMetadata => {
  const defaultString = 'Not available';
  const currentUser: ICurrentUser | {[key: string]: string} = getCurrentUser() || {};

  return {
    user: currentUser?.email || defaultString,
    userId: currentUser?.userId || defaultString,
    tenantId: currentUser?.tenantId || defaultString,
    tenantName: getCurrentTenantName() || defaultString
  };
};

export const getCurrentUser = (): ICurrentUser | {[key: string]: string} => {
  try {
    // Is payload is a valid json string
    return JSON.parse(localStorage.getItem(LOCALSTORAGE_CONST.CURRENT_USER) ?? '{}');
  } catch (_error) {
    // Otherwise send empty object
    return {};
  }
};

export const getCurrentTenantName = (): string => {
  const currentUser: ICurrentUser | {[key: string]: string | string[]} = getCurrentUser();
  const currentTenantId = currentUser?.tenantId;

  const tenants = (currentUser?.tenants as ICurrentTenant[]) || [];

  return tenants.filter((t: {tenantId: string; tenantName: string}) => t.tenantId === currentTenantId)[0]?.tenantName;
};

export interface ICurrentUser {
  accessToken: string;
  email: string;
  firstName: string;
  groups: string[];
  lastName: string;
  permissions: string[];
  refreshToken: string;
  sortedGroups: {[key: string]: string[]};
  tenantId: string;
  tenants: ICurrentTenant[];
  userId: string;
  username: string;
  verificationStatus: boolean;

  mfaConfirmationEnabled: boolean;
  mfaConfirmationUri: string | null;
}

export interface ICurrentTenant {
  redirectUrl: string;
  region: string;
  tenantId: string;
  tenantName: string;
}

export const LOCALSTORAGE_CONST = {
  CURRENT_USER: 'currentUser'
} as const;
